<template>
    <div>
        <h2>Enter a keyword:</h2>
        <form @submit.prevent="searchNaverNewsAndEvaluate">
            <div class="mb-3">
                <label for="keyword" class="form-label">Conduct your own experiment by entering a keyword related to the topic you wish to explore. We will retrieve the 20 most recent news titles covering the subject using Naver OpenAPI. The ChatGPT API will then evaluate and present the top three news articles from those news titles. The evaluation criteria are: 'Relevance', 'Importance', 'Novelty', 'Clarity', 'Objectivity', 'Trustworthiness', and 'Insightfulness'.</label>
                <input type="text" class="form-control" id="keyword" v-model="keyword">
            </div>
            <button type="submit" class="btn btn-primary" :disabled="!keyword.trim()">Search News with Naver Open API</button>
        </form>
        <div v-if="isLoading" class="spinner-border text-primary my-4" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <h2 v-if="isLoading" class="my-4">Please wait for a while. ChatGPT is now evaluating the news titles for the
            keyword.</h2>
        <br>
        <div class="row">
            <div v-for="(article, index) in articles" :key="index" class="col-lg-4 mb-4">
                <div class="card h-100">
                    <div class="card-body">
                        <h3 class="card-title">{{ article.title }}</h3>
                        <a :href="article.link" target="_blank" rel="noopener noreferrer" class="card-link">{{ article.link }}</a>
                        <div v-for="(score, criterion) in article.scores" :key="criterion" class="score mt-2">
                            <strong>{{ scoreLabels[criterion] }}:</strong> {{ score }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-if="articles.length > 0">
            <h3>Top 3 News Articles</h3>
            <div v-for="(article, index) in articles" :key="index">
                <h4>{{ article.title }}</h4>
                <p>Link: <a :href="article.link" target="_blank">{{ article.link }}</a></p>
                <div v-for="(score, key) in article.scores" :key="key">
                    <p>{{ key }}: {{ score }}</p>
                </div>
            </div>
        </div> -->
    </div>
</template>
  
  
<script>
export default {
    name: 'ExperimentPage',
    data() {
        return {
            keyword: '',
            articles: [],
            scoreLabels: {
                A: 'Relevance',
                B: 'Importance',
                C: 'Novelty',
                D: 'Clarity',
                E: 'Objectivity',
                F: 'Trustworthiness',
                G: 'Insightfulness',
                Overall_Rationale: 'Evaluation Summary'
            },
            isLoading: false,
        };
    },
    // computed: {
    //     scoreLabels() {
    //         return {
    //             A: 'Relevance',
    //             B: 'Importance',
    //             C: 'Novelty',
    //             D: 'Clarity',
    //             E: 'Objectivity',
    //             F: 'Trustworthiness',
    //             G: 'Insightfulness',
    //             Overall_Rationale: 'Evaluation Summary'
    //         };
    //     }
    // },

    methods: {
        async searchNaverNewsAndEvaluate() {
            this.isLoading = true;
            try {

                // const response = await fetch('http://localhost:3000/experiment_yourself/kr', {
                const response = await fetch('https://backendnewspickai-rzwiqbdh5q-du.a.run.app/experiment_yourself/kr', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ keyword: this.keyword }),
                });
                // const fetchedNewsTitles = await response.json();

                // // Perform evaluation and get the top 3 articles
                // const evaluatedArticles = await yourEvaluationMethod(fetchedNewsTitles);
                const evaluatedArticles = await response.json();
                this.articles = evaluatedArticles;
            } catch (error) {
                console.error('Error fetching news:', error);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>
  