<template>
    <div id="app">
        <!-- <header class="bg-primary text-white text-center py-3">
        <h1>Welcome to newspick.ai</h1>
      </header> -->

        <div class="container my-5">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <p class="text-justify">
                        Newspick.ai is an experiment aimed at demonstrating that the value of news articles should be
                        determined
                        by their true significance, rather than the number of clicks they receive. Our goal is to promote
                        high-quality
                        journalism and help users discover the most valuable and relevant news content.
                    </p>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-8 mx-auto">
                    <label for="country-select" class="form-label">Select Country:</label>
                    <select id="country-select" class="form-select" v-model="selectedCountry">
                        <option value="kr">South Korea (KR)</option>
                        <option value="us">United States (US)</option>
                        <option value="cn">China (CN)</option>
                        <option value="jp">Japan (JP)</option>
                        <option value="fr">France (FR)</option>
                    </select>
                </div>
                <p class="text-center"><strong>Last news update:</strong> {{ lastUpdate }}</p>
            </div>

            <h2 class="my-4">Top 3 News</h2>
            <div class="row">
                <div v-for="(article, index) in articles" :key="index" class="col-lg-4 mb-4">
                    <div class="card h-100">
                        <div class="card-body">
                            <h3 class="card-title">{{ article.title }}</h3>
                            <a :href="article.link" target="_blank" rel="noopener noreferrer" class="card-link">{{ article.link }}</a>
                            <div v-for="(score, criterion) in article.scores" :key="criterion" class="score mt-2">
                                <strong>{{ scoreLabels[criterion] }}:</strong> {{ score }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
  
<script>
export default {
    name: 'App',
    data() {
        return {

            articles: [], // Add this line to initialize the articles array
            // selectedCountry: 'kr', // Add this line to initialize the selectedCountry property
            selectedCountry: localStorage.getItem('selectedCountry') || 'kr', // Using LocalStorage
            lastUpdate: '', // Add this line to initialize the lastUpdate property

        };
    },
    computed: {
        scoreLabels() {
            return {
                A: 'Relevance',
                B: 'Importance',
                C: 'Novelty',
                D: 'Clarity',
                E: 'Objectivity',
                F: 'Trustworthiness',
                G: 'Insightfulness',
                Overall_Rationale: 'Evaluation Summary'
            };
        }
    },

    watch: {
        async selectedCountry(newCountry) {
            localStorage.setItem('selectedCountry', newCountry); // Using LocalStorage
            await this.fetchNews(newCountry);
        }
    },

    methods: {
        async fetchNews(country) {
            try {
                // const response = await fetch(`http://localhost:3000/get_saved_news/${country}`);
                const response = await fetch(`https://backendnewspickai-rzwiqbdh5q-du.a.run.app/get_saved_news/${country}`);
                const data = await response.json();
                this.articles = data.articles; // Add '.articles' here
                this.lastUpdate = data.lastUpdate; // Add this line to update the lastUpdate property
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    },
    async created() {
        try {
            // const response = await fetch(`http://localhost:3000/get_saved_news/${this.selectedCountry}`);
            const response = await fetch(`https://backendnewspickai-rzwiqbdh5q-du.a.run.app/get_saved_news/${this.selectedCountry}`);
            const data = await response.json();
            this.articles = data.articles;
            this.lastUpdate = data.lastUpdate; // Update the lastUpdate property
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    },
    // async created() {
    //   await this.fetchNews(this.selectedCountry);
    // },
};
</script>
  
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.news-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px 0;
}

.news-item {
    flex: 1;
    max-width: 350px;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.score {
    margin-bottom: 5px;
}
</style>
  