// import { createApp } from 'vue'
// import App from './App.vue'
// import 'bootstrap/dist/css/bootstrap.min.css';
// createApp(App).mount('#app')


// import { createApp } from 'vue';
// import App from './App.vue';
// import router from './router';
// createApp(App).use(router).mount('#app');


import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the router
import 'bootstrap/dist/css/bootstrap.min.css';
import VueGtag from 'vue-gtag';

const app = createApp(App);
// app.use(router); // Add the router to the Vue application
app.use(VueGtag, {
    property: {
      id: 'G-8L3F1ESLZK',
    },
  }, router); // Add the vue-gtag to the Vue application with router

app.use(router); // Add the router to the Vue application
app.mount('#app');