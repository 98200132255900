import { createRouter, createWebHistory } from 'vue-router';
import MainNews from './components/MainNews.vue';
import Experiment from './components/Experiment.vue';

const routes = [
  { path: '/', component: MainNews },
  { path: '/experiment', component: Experiment },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
