<template>
  <div id="app">
    <header class="bg-primary text-white text-center py-3">
      <h1>Welcome to newspick.ai</h1>
    </header>

    <nav class="my-4">
      <router-link to="/" class="btn btn-outline-primary me-2">Main News</router-link>
      <router-link to="/experiment" class="btn btn-outline-primary">Experiment Yourself</router-link>
    </nav>

    <div class="container my-5">
      <router-view></router-view>
    </div>
  </div>
</template>
